import React, { useState, useEffect } from "react";
import moment from "moment";
import { Row, Table,  Col, DatePicker, Button, notification, Select, Form} from "antd";
import { getRegisteredTitlesApi} from "../services/supplierApi";
import { getInvoicesSuppliersHistoryApi, getInvoicesXlsxDonwloadApiApi} from "../services/invoiceApi";
import { convertFloatDotSeperated } from "../helpers/common";
import '../../node_modules/moment/locale/tr';
import locale from '../../node_modules/antd/es/date-picker/locale/tr_TR';


function AllTransactions() {
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalDataCount, setTotalDataCount] = useState<any>(1);
  const [supplierInvoiceHistory, setSupplierInvoiceHistory] = useState<any>([]); 
  const [registeredSuppliers, setRegisteredSuppliers] = useState<any>([]);
  const [show, setShow] = useState(false);
  const [supplierTitleId,setSupplierTitleId] = useState('');

  const [title,setTitle] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [transactionType, setTransactionType] = useState('')

  const [form] = Form.useForm();
 
  const getRegisteredTitles = async () => {
    try {
       const response: any = await getRegisteredTitlesApi();
      if (response) {
        setRegisteredSuppliers(response.data.registeredSuppliers);
      } 
    } catch (e:any) {
      console.log(e)      
    }
  };

  const getInvoicesSuppliersHistory = async (startDate:string, finishDate: string) => {
    try {
        setLoading(true)
        const response: any = await getInvoicesSuppliersHistoryApi(supplierTitleId, transactionType === '0' ? '' : transactionType , startDate, finishDate, pageNumber, pageSize);
      if (response) {
        setSupplierInvoiceHistory(response.data.supplierInvoiceHistory.data);
        setTotalDataCount(response.data.supplierInvoiceHistory.totalDataCount)
        setLoading(false)
      } 
    } catch (e:any) {
      setLoading(false)
      console.log(e)      
    }
  };

   const  disabledDate = (current: any) => {  
    return (
      (new Date(current).getDay() === 0 ||
      new Date(current).getDay() === 6)
    );
  } 

  const handleChange = (value: string) => {
    setTitle('');
    setEndDate('');
    setStartDate('')
    if(value){
      setSupplierTitleId(value);
      const supTitle:any [] = registeredSuppliers.filter((item:any) => item.id === value)
      setTitle(supTitle[0].supplierTitle)
      form.resetFields();
      setShow(false);
    }
  };

  const handleChangeTransactionType = (value: string) => {
    setEndDate('');
    setStartDate('')
    if(value){
      setTransactionType(value);
      form.resetFields();
      setShow(false);
    }
  };

  const startDateChange = (val:any) => {
    if(val !== null){
      setStartDate(moment(val).format('YYYY-MM-DD'))
    }else{
      setStartDate('')
    }
  }

  const endDateChange = (val:any) => {
    if(val !== null){
      setEndDate(moment(val).format('YYYY-MM-DD'))
    }else{
      setEndDate('')
    }
  }

  const onchangeValue = async () => {  
    if(supplierTitleId !== ''){
      if(startDate !== ''){
        getInvoicesSuppliersHistory( startDate, endDate );
        setShow(true);
      }else{
        notification.warning({
          message: `Başlangınç tarihi zorunlu seçilmeli.`,
        });
        form.resetFields();
        setShow(false);
      }
    }else{
      notification.warning({
        message: `Tedarikçi seçimi yapmalısınız.`,
      });
    }
    // eslint-disable-next-line no-console
  };

  const xlsxDonwload = async () => {
    setLoading(true)
    try{
      const response:any = await getInvoicesXlsxDonwloadApiApi(supplierTitleId, transactionType === '0' ? '' : transactionType , startDate, endDate, 1, totalDataCount);
      if (response) {
        const link = document.createElement('a');
        link.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64, ${encodeURIComponent(response?.data?.xlsxFileReport)}` ;
        link.setAttribute('download', `${title} Tedarikçi Hareketleri.xlsx`); 
      
        link.style.display = 'none';
        document.body.appendChild(link);      
        link.click();      
        document.body.removeChild(link);
        setLoading(false)
      }
    }catch(e:any){ }
  }

  const tableCols: any = [
    {
      title: "İşlem Tarihi",
      dataIndex: "createdDate",
      key: "createdDate",
      width: 150,
      fixed: 'left',
      render: (value: string) => {
        return moment(value).format("DD-MM-YYYY");
       }
    },
    {
      title: "Fatura Tutarı",
      key: "transactionAmount",
      align: 'center',
      width: 150,
      render: (row: any) => {
        return  `${convertFloatDotSeperated(row.transactionAmount)} TL` ;
       }
    },
    {
      title: "İşlem Tipi",
      dataIndex: "transactionType",
      key: "transactionType",
      align: 'center',
      width: 150,
      render: (value:any) => {
        return value === 1 ? 'Fatura Yükleme': value === 2 ? 'Fatura Vadesi Güncelleme' : value === 3 ?'Fatura Silme' : '';
       }
    },
    {
      title: "Açıklama",
      dataIndex: "description",
      key: "description",
      align: 'left',
    },
  ];
  
  const handleShowSize = (current: any, size: any) => {
    setPageNumber(current);
    setPageSize(size);
  };
  

  useEffect(() => {
    if(supplierTitleId){
      getInvoicesSuppliersHistory( startDate, endDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, pageSize]);

  useEffect(() => {
    getRegisteredTitles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main>
      <Row style={{ marginTop: 50 }}>
        <Col span={24}>
          <h3 className="page-title">Fatura Hareketleri İzleme</h3>
        </Col>
      </Row>
      <Row>
          <Col style={{ margin: '30px 0 20px' }}>
            <p>Lütfen haraketlerini görüntülemek istediğiniz tedarikçiyi seçiniz.</p>
            <div style={{textAlign:'left'}}>
              <Select
                defaultValue="Lütfen seçim yapınız."
                style={{ width: 400 }}
                onChange={handleChange}
                options={ registeredSuppliers && registeredSuppliers.map((item:any) => {
                  return(
                      { value: item.id, label: item.supplierTitle}
                    )
                })
                }
              />
            </div> 
          </Col>
      </Row>
      <Row style={{marginBottom:'30px'}} >
          <Col style={{ margin: '0 0 20px' }}>
            <p>Lütfen işlem türünü seçiniz.</p>
            <div style={{textAlign:'left'}}>
              <Select
                defaultValue="Lütfen seçim yapınız."
                style={{ width: 400 }}
                onChange={handleChangeTransactionType}
                options={[
                  {
                    value: '0',
                    label: 'Tüm işlemler',
                  },
                  {
                    value: '1',
                    label: 'Fatura Yükleme',
                  },
                  {
                    value: '2',
                    label: 'Fatura Vadesi Güncelleme',
                  },
                  {
                    value: '3',
                    label: 'Fatura Silme',
                  },
                ]}
              />
            </div> 
          </Col>
      </Row>
      <Row>
          <Col >
            <Form
              form={form}
               name="filter-box"
              //  onFinish={onchangeValue}
               autoComplete="off"
               labelCol={{
                 span: 8,
               }}
               size = "large"
               style={{
                 minWidth: 400,
               }}
            >
              <div className='filter-row suppliers-movie' style={{alignItems:'center'}}>
                <div>
                    <span style={{display:'flex', alignItems:'center', paddingLeft:'1px'}}>Başlangıç Tarihi<pre style={{marginBottom:'0', color:'#f00', fontSize:'11px'}}> *</pre></span>
                    <Form.Item
                      name='startDate'
                    >
                      <DatePicker  locale={locale} 
                        format='DD/MM/YYYY'   
                        placeholder='Tarih Seçiniz'  
                        disabledDate={disabledDate}
                        onChange={(value) => startDateChange(value)}  
                         />
                    </Form.Item>                  
                </div>
                <div>
                    <span style={{paddingLeft:'1px'}}>Bitiş Tarihi</span>
                    <Form.Item
                      name='endDate'
                    >
                      <DatePicker 
                      locale={locale} 
                      format='DD/MM/YYYY'   
                      placeholder='Tarih Seçiniz'  
                      disabledDate={disabledDate}
                      onChange={(value) => endDateChange(value)} 
                       />
                    </Form.Item>                  
                </div>
                <div>
                  <Form.Item style={{marginBottom:'0'}}>
                    <Button
                      type="primary"
                      htmlType="submit"  
                      loading={loading}
                      onClick={onchangeValue}              
                    >Filtrele
                    </Button>
                  </Form.Item>
                </div>
              </div>
            </Form>
          </Col>
        </Row>  
      {
        show ?
        (
          <>
            <Row  style={{justifyContent:'end', marginBottom:'20px'}}>
              <Button type="primary" size="large" onClick={xlsxDonwload} loading={loading} disabled={supplierInvoiceHistory.length < 1}>Excel İndir</Button>
            </Row>
            <Row>
            <Col span={24} style={{ margin: "0px auto" }}>
                <Table
                  rowKey="invoiceNumber"
                  loading={loading}
                  dataSource={supplierInvoiceHistory}
                  columns={tableCols}
                  pagination={{
                    position: ["topRight", "none" as any],
                    showLessItems: true,
                    showSizeChanger: true,
                    pageSize,
                    total: totalDataCount,
                    onChange: handleShowSize,
                    
                  }}
                  scroll={{
                    x: 1200,
                  }}
                />
              
            </Col>
            </Row>
          </>
        ):
        (
          ""
        )
      }
      
    </main>
  );
}

export default AllTransactions;
