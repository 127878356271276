import React, { useState, useEffect } from "react";
import moment from "moment";
import { Row, Table,  Col, DatePicker, Button, notification, Select, Form, Checkbox} from "antd";
import { DownOutlined } from '@ant-design/icons';
import { getAgriculturistPrePaidReportApi, getPrePaidReportXlsxApi, getAgriculturistRegisteredApi} from "../../services/agriculturistApi";
import { getFinanceTitlesApi} from "../../services/financingApi";
import { convertFloatDotSeperated } from "../../helpers/common";
import '../../../node_modules/moment/locale/tr';
import locale from '../../../node_modules/antd/es/date-picker/locale/tr_TR';


function ListTransactions() {

  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalDataCount, setTotalDataCount] = useState<any>(1);
  const [registeredAgro, setRegisteredAgro] = useState<any>([]);
  const [financialInstitutionTitles, setFinancialInstitutionTitles] = useState<any>([]);
  const [displayedValues1, setDisplayedValues1] = useState<string[]>([]);
  const [selectedValuesFinancial, setSelectedValuesFinancial] = useState<any>([]);
  const [selectedValuesSuppliers, setSelectedValuesSuppliers] = useState<any>([]);
  const [report, setReport] = useState<any>([]); 
  const [title,setTitle] = useState('');
  const [show, setShow] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const [form] = Form.useForm();

  const  disabledDate = (current: any) => {  
    return (
      (new Date(current).getDay() === 0 ||
      new Date(current).getDay() === 6)
    );
  } 

  const getRegisteredAgriculturist = async () => {
    setLoading(true);
    try {
       const response: any = await getAgriculturistRegisteredApi();
      if (response) {
        setLoading(false);
        setRegisteredAgro(response.data.agriculturists);
      } 
    } catch (e:any) {
      console.log(e)      
    }
  }
 
  const getFinanceTitles = async () => {
    setLoading(true);
    try {
       const response: any = await getFinanceTitlesApi();
      if (response) {
        setLoading(false);
        setFinancialInstitutionTitles(response.data.financialInstitutionTitles);
      } 
    } catch (e:any) {
      console.log(e)      
    }
  };

  const getSuppliersUsageReport = async (startDate:string, finishDate: string) => { 
    const supplierData = `supplierIds=${selectedValuesSuppliers}`;
    const financialData = selectedValuesFinancial.map((id:any) => `financialInstitutionIds=${id}`).join('&');
    try {
        setLoading(true)
        const response: any = await getAgriculturistPrePaidReportApi(startDate, finishDate, financialData, supplierData, pageNumber, pageSize);
      if (response) {
        setReport(response.data.discountApplicationInfos.data);
        setTotalDataCount(response.data.discountApplicationInfos.totalDataCount);
        setLoading(false)
      } 
    } catch (e:any) {
      setLoading(false)
      console.log(e)      
    }
  };

  const handleChange = (value: string) => {
    if(value){
      setSelectedValuesSuppliers(value)
      const supTitle:any [] = registeredAgro.filter((item:any) => item.id === value)
      setTitle(supTitle[0].supplierTitle)
    }
  };

  const allValues = financialInstitutionTitles.map((item:any) => item.id);
  const handleChangeFinancial = (values: string[]) => {
    setShow(false);
    setReport([]);
    if (values.includes("all")) {
      if (selectedValuesFinancial.length === allValues.length) {
        setSelectedValuesFinancial([]);
        setDisplayedValues1([]);
      } else {
        setSelectedValuesFinancial(allValues);
        setDisplayedValues1(["all"]);
      }
    } else {
      setSelectedValuesFinancial(values);
      setDisplayedValues1(values);
    }
  };

  const startDateChange = (val:any) => {
    setDisplayedValues1([]);
    setEndDate('');
    setSelectedValuesFinancial([]);
    setSelectedValuesSuppliers([]);
    setShow(false);
    if(val !== null){
      setStartDate(moment(val).format('YYYY-MM-DD'))
    }else{
      setStartDate('')
    }
  }

  const endDateChange = (val:any) => {
    if(val !== null){
      setEndDate(moment(val).format('YYYY-MM-DD'))
    }else{
      setEndDate('')
    }
  }

  const onchangeValue = async () => {  
    if(Object.keys(selectedValuesSuppliers).length > 0 || Object.keys(selectedValuesFinancial).length > 0 ){
      if(startDate !== ''){
        getSuppliersUsageReport( startDate, endDate);
        setShow(true);
      }else{
        notification.warning({
          message: `Başlangınç tarihi zorunlu seçilmeli.`,
        });
        form.resetFields();
        setShow(false);
      }
    }else{
      notification.warning({
        message: `Çiftçi ve Finans kurumu seçimi yapmalısınız.`,
      });
    }
    // eslint-disable-next-line no-console
  };
  
  const handleShowSize = (current: any, size: any) => {
    setPageNumber(current);
    setPageSize(size);
  };

  const xlsxDonwload = async () => {
    setLoading(true);
    const supplierData = `supplierIds=${selectedValuesSuppliers}`;
    const financialData = selectedValuesFinancial.map((id:any) => `financialInstitutionIds=${id}`).join('&');
    try{
      const response:any = await getPrePaidReportXlsxApi(startDate, endDate, financialData, supplierData, pageNumber, totalDataCount);
      if (response) {
        const link = document.createElement('a');
        link.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64, ${encodeURIComponent(response?.data?.xlsxFileReport)}` ;
        link.setAttribute('download', `${title} Tedarikçi Hareketleri.xlsx`); 
      
        link.style.display = 'none';
        document.body.appendChild(link);      
        link.click();      
        document.body.removeChild(link);
        setLoading(false)
      }
    }catch(e:any){ }
  }

  const tableCols: any = [
    {
      title: "Başvuru Tarih",
      dataIndex: "discountApplicationDate",
      key: "discountApplicationDate",
      width: 70,
      fixed: 'left',
      render: (value: string) => {
        return moment(value).format("DD-MM-YYYY");
       }
    },
    {
      title: "Başvuru No",
      key: "discountApplicationNumber",
      dataIndex: "discountApplicationNumber",
      width: 70,
    },
    {
      title: "Tedarikçi Ünvanı",
      key: "ownerTitle",
      dataIndex: "ownerTitle",
      width: 180,
    },
    {
      title: "Başvuru Tutarı",
      key: "invoiceTotal",
      dataIndex: "invoiceTotal",
      width: 80,
      render: (value: any) => {
        return  `${convertFloatDotSeperated(value)} TL` ;
       }
    },
    {
      title: "İşlem Fatura Tutarı",
      key: "approvedInvoiceTotal",
      dataIndex: "approvedInvoiceTotal",
      width: 80,
      render: (value: any) => {
        return  `${convertFloatDotSeperated(value)} TL` ;
       }
    },
    {
      title: "İşlem Faiz Kesintisi",
      key: "interestAndCommissionAmount",
      dataIndex: "interestAndCommissionAmount",
      width: 80,
      render: (value: any) => {
        return  `${convertFloatDotSeperated(value)} TL` ;
       }
    },
    {
      title: "İşlem Paçal Faiz Oranı",
      key: "aggregateInterestRate",
      dataIndex: "aggregateInterestRate",
      width: 70,
      render: (value: string) => {
        return `${value} %`;
       }
    },
    {
      title: "İşlem Ortalama Vadesi",
      key: "averageMaturity",
      dataIndex: "averageMaturity",
      width: 80,
    },
    
    
  ];

useEffect(() => {
  if(startDate){
    getSuppliersUsageReport( startDate, endDate);
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [pageNumber, pageSize]);

useEffect(() => {
  getRegisteredAgriculturist();
  getFinanceTitles();
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

  return (
    <main>
      <Row style={{ marginTop: 50 }}>
        <Col span={24}>
          <h3 className="page-title">İşlemler Listesi</h3>
        </Col>
      </Row>
      <Row>
        <Col style={{ marginTop: '30px' }} span={24}>
          <Form
            form={form}
            name="filter-box"
            autoComplete="off"
            labelCol={{
              span: 8,
            }}
            size="large"
            style={{
              minWidth: 500,
            }}
          >
            <div className='filter-row suppliers-movie' style={{alignItems:'end'}}>
              <div>
                <span style={{ display: 'flex', alignItems: 'center', paddingLeft: '1px' }}>Başlangıç Tarihi<pre style={{ marginBottom: '0', color: '#f00', fontSize: '11px' }}> *</pre></span>
                <Form.Item
                  name='startDate'
                >
                  <DatePicker locale={locale}
                    format='DD/MM/YYYY'
                    placeholder='Tarih Seçiniz'
                    disabledDate={disabledDate}
                    onChange={(value) => startDateChange(value)}
                  />
                </Form.Item>
              </div>
              <div>
                <span style={{ paddingLeft: '1px' }}>Bitiş Tarihi</span>
                <Form.Item
                  name='endDate'
                >
                  <DatePicker
                    locale={locale}
                    format='DD/MM/YYYY'
                    placeholder='Tarih Seçiniz'
                    disabledDate={disabledDate}
                    onChange={(value) => endDateChange(value)}
                  />
                </Form.Item>
              </div>
            
            </div>
          </Form>
        </Col>
      </Row> 

      <Row style={{ marginBottom: '20px' }} >
          <Col style={{ margin: '0 0 10px' }}>
            <p>Lütfen işlem listesini görüntülemek istediğiniz çiftçi seçiniz.</p>
            <div style={{textAlign:'left'}}>
              <Select
                loading={loading}
                defaultValue="Lütfen seçim yapınız."
                className="select-bg-new"
                style={{ width: 400 }}
                onChange={handleChange}
                options={ registeredAgro && registeredAgro.map((item:any) => {
                  return(
                      { value: item.id, label: item.name}
                    )
                })
                }
              />
            </div> 
          </Col>
      </Row>   
 
      <Row style={{ marginBottom: '30px' }}>
          <Col span={24}>
            <p>Lütfen seçmiş olduğunuz tedarikçinin işlem listesini görüntülemek istediğiniz finans kurumunu seçiniz.</p>
            <div style={{ position: "relative", maxWidth: 400 }}>
              <Select
                mode="multiple"
                placeholder="Lütfen seçim yapınız."
                style={{ width: "100%" }}
                value={displayedValues1}
                onChange={handleChangeFinancial}
                dropdownRender={(menu) => (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "8px",
                        borderBottom: "1px solid #f0f0f0",
                      }}
                    >
                      <Checkbox
                        indeterminate={
                          selectedValuesFinancial.length > 0 &&
                          selectedValuesFinancial.length < allValues.length
                        }
                        checked={selectedValuesFinancial.length === allValues.length}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedValuesFinancial(allValues);
                            setDisplayedValues1(["all"]);
                          } else {
                            setSelectedValuesFinancial([]);
                            setDisplayedValues1([]);
                          }
                        }}
                      >
                        Hepsi
                      </Checkbox>
                    </div>
                    {menu}
                  </div>
                )}
                options={[
                  { value: "all", label: "Hepsi" },
                  ...financialInstitutionTitles.map((item: any) => ({
                    value: item.id,
                    label: (
                      <Checkbox
                        checked={selectedValuesFinancial.includes(item.id)}
                        onChange={() => { }}
                      >
                        {item.name}
                      </Checkbox>
                    ),
                  })),
                ]}
              />
              <div
                style={{
                  position: "absolute",
                  right: 10,
                  top: "18px",
                  transform: "translateY(-50%)",
                  pointerEvents: "none",
                  fontWeight: "900",
                }}
              >
                <DownOutlined />
              </div>
            </div>
          </Col>
        </Row>
   
      <Row>
        <Col>
          <div className="filter-row">
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                onClick={onchangeValue}
              >Filtrele
              </Button>
            </Form.Item>
          </div>
        </Col>
      </Row>
      {
        show ?
        (
            <>
            <Row  style={{justifyContent:'end', marginBottom:'20px'}}>
              <Button type="primary" size="large" onClick={xlsxDonwload} loading={loading} disabled={report.length < 1}>Excel İndir</Button>
            </Row>

              <Row>
                <Col span={24} style={{ margin: "0px auto" }}>
                
                  <Table
                  rowKey="invoiceNumber"
                  loading={loading}
                  dataSource={report.reverse()}
                  columns={tableCols}
                  pagination={{
                    position: ["topRight", "none" as any],
                    showLessItems: true,
                    showSizeChanger: true,
                    pageSize,
                    total: totalDataCount,
                    onChange: handleShowSize,
                    
                  }}
                  scroll={{
                    x: 1200,
                  }}
                />

                </Col>
              </Row>
            </>
        ):
        (
          ""
        )
      }

      
      
    </main>
  );
}

export default ListTransactions;
