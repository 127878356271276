import React, { useState, useEffect } from "react";
import {
  Row,
  Typography,
  Col,
  Form,
  notification,
  Button,
  Collapse,
  Input,
  Upload, Modal,Divider
} from "antd";
import { getAllSuppliersLimit, updatedLimit, getXlsxDonwloadSupplierLimitsApi, sendXlsxUploadSupplierLimitsApi,getXlsxDonwloadLimitNowApi } from "../../services/supplierApi";
import { CheckCircleOutlined, CloseCircleOutlined, PlusCircleOutlined, MinusCircleOutlined, FileOutlined,ExclamationCircleOutlined} from "@ant-design/icons";
import { convertFloatDotSeperated } from "../../helpers/common";
import { Link } from 'react-router-dom';

const { Text } = Typography;
const Panel = Collapse.Panel;


const SupplierLimit = () => {
  const [form] = Form.useForm();
  const [supliers, setSupliers] = useState<any>([]);
  const [buyerDefinitions, setBuyerDefinitions] = useState<any>([]); 
  const [bankLimitTotal, setBankLimitTotal] = useState<any>();
  const [bankRiskTotal, setBankRiskTotal] = useState<any>();
  const [supplierCountTotal, setSupplierCountTotal] = useState<any>();
  const [inputValue, setInputValue] = useState<any>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [summary, setSummary] = useState<any>([]);
 
  interface IsupplierLimitsUpdate {      
    supplierLimits: Array<IsupplierLimits>;  
    financialInstitutionId : string   
  }
  interface IsupplierLimits {      
    supplierId: string; 
    limit: number; 
    supplierTaxNumber: string;
    financialInstitutionId : string
  }  
  let newData = {} as IsupplierLimitsUpdate;
  newData.supplierLimits = [];
  newData.financialInstitutionId = ''

  const onchangeValue = (value:any) => {
    if(value.title !== undefined){
      setSearchQuery(value.title)
    }
   
  }

  const fetchAllSuppliers  = async () => {
    try {
      
      const response: any = await getAllSuppliersLimit(searchQuery);
      if (response) {
        setSupliers(response.data.supplierLimits);
        setBuyerDefinitions(response.data.buyerDefinitions); 
      }
    } catch (error: any) {      
      console.log(error)     
      notification.error({
        message: 'Beklenmedik bir hata oluştu..',
        duration: 3,
      });
    }
  }

  const updateFinanceLimit = async (value :any, financialId:any, supplierId:any,supplierTaxId:any) => {  
    
    let newItem = {} as IsupplierLimits;
    newItem.supplierId = supplierId;
    newItem.limit = value;
    newItem.supplierTaxNumber = supplierTaxId;
    newData.supplierLimits.push(newItem);   
    newData.financialInstitutionId = financialId;
          
    if(value !== ''){
      try{
        const response: any = await updatedLimit(newData);               
        if(response?.status === 200){
            notification.success({message: 'Limit başarıyla güncellenmiştir.'});  
             window.location.reload();
           
        }         
      }catch(e:any){

        if(e.response.data.Status === 406 && e.response.data.Type.includes('BuyerLimitExcessException')){
          notification.warning({message: 'Tedarikçi Limiti Alıcı Limitinden Fazla Olamaz.'});
        }  
        if(e.response.data.Status === 406 && e.response.data.Type.includes('InvalidSupplierLimitException')){
          notification.warning({message: "Tedarikçi Limiti 0' dan küçük olamaz." });
        }    
        if(e.response.data.Status === 404 && e.response.data.Type.includes('SupplierLimitDoesNotExistException')){
          notification.warning({message: "Tedarikçi limiti tanıımlanmamıştır." });
        }     
      }     
    }else{
      notification.success({message: 'Güncellenecek limit değerini girmediniz.'});
    }    
  }

  const xlsxDonwload = async () => {
    setLoading(true)
    try{
      const response:any = await getXlsxDonwloadSupplierLimitsApi();
      if (response) {
        const link = document.createElement('a');
        link.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64, ${encodeURIComponent(response?.data?.xlsxFileReport)}` ;
        link.setAttribute('download', `Anlık Limit Risk Durumu.xlsx`); 
      
        link.style.display = 'none';
        document.body.appendChild(link);      
        link.click();      
        document.body.removeChild(link);
        setLoading(false)
      }
    }catch(e:any){setLoading(false) }
  }

  const xlsxDonwloadLimitNow = async () => {
    setLoading(true)
    try{
      const response:any = await getXlsxDonwloadLimitNowApi();
      if (response) {
        const link = document.createElement('a');
        link.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64, ${encodeURIComponent(response?.data?.base64File)}` ;
        link.setAttribute('download', `Toplu Tedarikçi Limitleri.xlsx`); 
      
        link.style.display = 'none';
        document.body.appendChild(link);      
        link.click();      
        document.body.removeChild(link);
        setLoading(false)
      }
    }catch(e:any){setLoading(false) }
  }

  const handleUpload = async (file:any) => {
    const isXls = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type === 'xlsx';
    if(file.size < 10485760){

      if(isXls){
        const formData = new FormData();
        formData.append("file", file);
        try {
          const response:any = await sendXlsxUploadSupplierLimitsApi(formData);
          if(response?.data?.totalInvalidLimitCount !== 0){
            setShowModal(true)
            setSummary(response.data.reportFile)
         
          }else{
              notification.success({
              message: 'Başarıyla yüklenmiştir.',
          });
          }
        } catch (e:any) {
          console.error( e);
          if(e.response.data.Status === 400 && e.response.data.Type.includes('InvalidFileFormatException')){
            notification.error({
                message:  e.response.data.Title,
            });
        }
        }
  
      }else{
        notification.error({
          message: "Yalnızca .xls ve .xlsx uzantılı dosya yükleyebilirsiniz!",
        });
      }
    }else{
      notification.error({
        message: "Lütfen 1 MB'tan küçük bir dosya yükleyin!",
      });
    }
   
  };

  const downloadSummary =  () => {
    if(summary){
      const link = document.createElement('a');
      link.href = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + encodeURIComponent(summary);
      link.setAttribute('download', 'Hata Raporu - Hata Raporu.xlsx'); 
      link.style.display = 'none';
      document.body.appendChild(link);      
      link.click();      
      document.body.removeChild(link);
    }else{
      console.log('hata oluştu')
    }    
  };

  useEffect(() => {
    fetchAllSuppliers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  useEffect(() => {
    setBankLimitTotal(buyerDefinitions.reduce((a:any,v:any) =>  a = a + v.limit , 0 ))
    setBankRiskTotal(buyerDefinitions.reduce((a:any,v:any) =>  a = a + v.risk , 0 ))  
    setSupplierCountTotal(buyerDefinitions.reduce((a:any,v:any) =>  a = a + v.supplierCount , 0 ))
  }, [buyerDefinitions]);

 
  const header = (supplierTitle:any,supplierTaxId:any, sumRisk:any, sumLimit:any, isRegistered:any) => {
    return (
      <div className="header" style={{display:'flex'}}>
        <div>{supplierTitle}</div>
        <div>{supplierTaxId}</div>
        <div>{convertFloatDotSeperated(sumRisk)} TL</div>
        <div>{convertFloatDotSeperated(sumLimit)} TL</div>
        <div>{sumRisk === 0 ? '-' : ((sumRisk * 100)/sumLimit)?.toFixed(2)} % </div>
        <div>{
            isRegistered 
            ? <CheckCircleOutlined style={{color: '#62b61f', fontSize: 22}} /> 
            : <CloseCircleOutlined style={{color: '#cc2c2c', fontSize: 22}} />
            }
        </div>
      </div>
    )
  }

  return (
    <main>
      <Row  className="my-suppliers">
      <Col span={24}  className="supplier-limit">       
      <Row>
        <Col xl={14} md={14}>
          <Text className="limit-title">Limit Bölüştür</Text><br/>
          <Text style={{fontWeight:'600'}}>Toplam limitinizi tedarikçileriniz arasında bölüştürün.</Text>  
        </Col>
        <Col xl={10} md={10} style={{display:'flex', justifyContent:'end'}}>
          <Button type="primary" style={{marginLeft:'10px'}}
             className="donwload-limit-now" 
             onClick={xlsxDonwload} loading={loading}>
              Anlık Limit Risk Durumunu Excel İndir</Button>
        </Col>  
      </Row>
      <Row>
        <Text style={{marginTop:'15px', fontSize:'20px '}}>
            Banka Risk/Limit Durumu 
          </Text>
      </Row>
      <Row className="limit-header-title">
          <Col span={24}>
              <div className="title buyer-definitions-title">
                  <div>Finans Kurumu</div>
                  <div>Mevcut Risk</div>
                  <div>Limit</div>
                  <div>Risk/ <br/> Limit %</div>
                  <div>Tedarikçi <br/> Adedi</div>
              </div>
              <div className="table-li buyer-definitions-li" style={{marginLeft:'0'}}>
                {
                  buyerDefinitions && buyerDefinitions.map((item:any,index:any) => {
                    return (
                      <div>
                        <div>{item.financialInstitutionName}</div>
                        <div>{convertFloatDotSeperated(item.risk)} TL</div>
                        <div>{convertFloatDotSeperated(item.limit)} TL</div>
                        <div>{item.risk === 0 ? '-' : ((item.risk * 100)/item.limit)?.toFixed(2)} %</div>
                        <div>{item.supplierCount}</div>                           
                    </div>
                    )
                  })
                }            
                
                <div>
                  <div>TOPLAM</div>
                  <div>{bankRiskTotal && convertFloatDotSeperated(bankRiskTotal)} TL</div>
                  <div>{bankLimitTotal && convertFloatDotSeperated(bankLimitTotal)} TL</div>
                  <div>{bankRiskTotal === 0 ? '-' : (bankRiskTotal && bankLimitTotal && (bankRiskTotal * 100)/bankLimitTotal)?.toFixed(2)} %</div>
                  <div>{supplierCountTotal && supplierCountTotal}</div>
                </div>
              </div>
          </Col>
        </Row>
      <Row style={{marginTop:'30px'}} className="update-batch-row">
        <Col xl={14} md={8}>
          <Text style={{marginTop:'15px', fontSize:'20px '}}>
            Tedarikçi Risk/Limit Durumu ve Güncelleme
          </Text><br/><br/>
          <Text style={{marginTop:'15px', fontStyle:'italic'}}><b>"Toplam Limitinizi "</b>Tedarikçileriniz arasında uygun gördüğünüz şekilde bölüştürebilirsiniz. Herhangi bir Tedarikçiye vereceğiniz limit en çok <b>“Toplam Limitiniz”</b> kadar olabilir. Ancak tüm Tedarikçilerinize toplam kullandırımınız <b>“Toplam Limitiniz”</b>i aşamaz.</Text>
        </Col> 
        <Col xl={10} md={4}>
          <div className="update-batch-btn-grp">
            <Text style={{marginBottom:'6px', fontSize:'15px', fontWeight:'bold'}}>
              Toplu Tedarikçi Limiti Güncelleme
            </Text>
            <div style={{display:'flex'}}>
              <Button type="primary" style={{marginRight:'10px'}} onClick={xlsxDonwloadLimitNow} loading={loading} >Excel İndir</Button>
              <Upload beforeUpload={handleUpload} showUploadList={false} accept=".xls,.xlsx">
                <Button type="primary">Excel Yükle</Button>
              </Upload>
            </div>
          </div>
        </Col>        
 
      </Row>         
      <Row style={{marginTop:'20px'}}>
          <Col span={10}>
            <Form
               name="filter-box"
               onFinish={onchangeValue}
               autoComplete="off"
               labelCol={{
                 span: 8,
               }}
               size = "large"
               style={{
                 minWidth: 400,
               }}
            >
              <div className='filter-row'>                
                <div>
                  <Form.Item name="title" >
                    <Input 
                      placeholder="Tedarikçi Adı veya VKN'sini Seçiniz"
                      style={{minWidth:'450px'}}
                     />
                    </Form.Item>
                </div>               
                <div>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"                 
                    >Ara</Button>
                  </Form.Item>
                </div>
              </div>
            </Form>
          </Col>
      </Row>
      <div className="limit-header-title-mobil">
        <Row className="limit-header-title">
          <Col span={24}>
              <div className="title">
                  <div>Tedarikçi Adı</div>
                  <div>VKN No</div>
                  <div>Mevcut Risk</div>
                  <div>Limit</div>
                  <div>Risk/ <br/> Limit %</div>
                  <div>Platforma <br/> Kayıt Durumu</div>
              </div>
          </Col>
        </Row>
        <Row >
          <Col span={24}>
            <Collapse 
              accordion 
              // defaultActiveKey={['0']} 
              expandIcon={({ isActive }) => (
                isActive ? <MinusCircleOutlined /> :  <PlusCircleOutlined />
              )}
              className="limit-acc-style" >
              {
                supliers && supliers.map((item :any,index:any) => {
                  return(
                    <Panel 
                      className="acc-row"
                      header={header(
                        item.supplierTitle,
                        item.supplierTaxId,
                        item.sumRisk,
                        item.sumLimit,
                        item.isRegistered,
                      )} 
                      key={index}
                    >
                    <div className="table-li">                   
                      {
                        item.risksAndLimits.map((finance:any, i:any) => {
                          return(
                            <div>
                              <div></div> 
                              <div>{finance.financialInstitutionName}</div>
                              <div>{convertFloatDotSeperated(finance.risk)} TL</div>
                              <div>
                                  <Form form={form} className="supplier-limit-input" style={{display:'flex'}}>
                                    <Form.Item 
                                      name={`limit${i}` }
                                      >
                                      <Input                                        
                                        placeholder={convertFloatDotSeperated(finance.limit)} 
                                          onInput={(e:any) => {
                                            setInputValue((prevArr: any) => {
                                              const result = [...prevArr];
                                              result[i] = e.target.value;
                                              return result;
                                            });
                                          }}
                                        className="no-spin input-width"
                                        style={{borderRadius:'8px'}}
                                        type="text"
                                      />           
                                    </Form.Item>
                                  <Form.Item>
                                    <Button 
                                      className="supplier-limit-btn"
                                      onClick={() => updateFinanceLimit(
                                        inputValue[i], 
                                        finance.financialInstitutionId, 
                                        item.supplierId,
                                        item.supplierTaxId
                                        ) } 
                                    >
                                      Güncelle
                                    </Button>
                                  </Form.Item>
                                </Form>                                
                              </div>
                              <div>{finance.risk === '0' ? '-' : ((finance.risk * 100)/finance.limit)?.toFixed(2)} % </div>  
                              <div></div>                         
                            </div>
                          )
                        })
                      }
                    </div>                  
                    </Panel>
                  )
                })
              }
              </Collapse>
          </Col>       
        </Row>
      </div>      
    </Col>
      </Row>
      <Modal
        className="delete-invoices-modal"
        open={showModal}
        width={630}
        footer={false} 
        onCancel={() => setShowModal(false)}
        okText="Evet"
        cancelText="Hayır"
      >
         <Row style={{marginBottom:'50px',marginTop:'15px'}}>
              <Col  span={24} style={{ margin: "0px auto" }} >
                <Divider style={{marginBottom:'20px'}}/>
                <div className="sum-information">
                  <div className="box box1">
                      <div className="icon-box">
                        <FileOutlined />
                      </div>
                      <span className="txt3">Toplam</span>
                      <span className="txt5">{summary.totalLoadedAgriculturistsCount} Adet</span>
                    </div>
                    <div className="box box3">
                      <div className="icon-box">
                        <CheckCircleOutlined />
                      </div>
                      <span className="txt3">Başarılı</span>
                      <span className="txt5">{summary.totalValidAgriculturistsCount} Adet</span>
                    </div>
                    <div className="box box4">
                      <div className="icon-box">
                        <ExclamationCircleOutlined />
                      </div>
                      <span className="txt3">Hatalı</span>
                      <span className="txt5">{summary.totalInvalidAgriculturistsCount} Adet</span>
                      <Link to=''  className="link" onClick={downloadSummary}>Hata Raporu</Link>
                    </div>    
                </div>
              </Col>
            </Row>
      </Modal>
    </main>
   
  );
};

export default SupplierLimit;
