import React from "react";
import {  Menu} from "antd";
import { Link } from "react-router-dom";
import { urls } from "../router/auth";
import {setNavShow} from "../reducers/userSlice";
import { useDispatch } from "react-redux";
import { LocalStorageService } from "../helpers/LocalStorage";

const { SubMenu } = Menu;

function MobilMenu() {
  const dispatch = useDispatch();
  const userBuyerType =  LocalStorageService.getBuyerType() === 'TEDARIKCI_ALICISI';

  const onOpenChange = (keys:any) => {
    dispatch(setNavShow(false));
  };

 
  return (
    <>
        <Menu onClick={onOpenChange} style={{ width:'100%' }} mode="inline" >
            <Menu.Item >
                <Link to={urls.myAccount}>
                    Hesap Bilgilerim 
                </Link>                                            
            </Menu.Item>
            {
                userBuyerType ? (
                    <>
                     <SubMenu
            key="sub1"
            title={
                <span>
                    <span>Fatura İşlemleri</span>
                </span>
            }
            >
                <Menu.Item key="m0">
                    <Link to={urls.updateMaturityInvoice}>
                        Fatura Vadesi Güncelleme
                    </Link>
                </Menu.Item>

                <Menu.Item key="m1">
                    <Link to={urls.uploadInvoice}>
                        Fatura Yükleme
                    </Link>   
                </Menu.Item>
                <Menu.Item key="m2">
                    <Link to={urls.deleteInvoice}>
                        Fatura Silme
                    </Link>
                </Menu.Item>
            </SubMenu>
            <SubMenu
            key="sub2"
            title={
                <span>
                    <span>Tedarikçi İşlemleri</span>
                </span>
            }
            >
                <Menu.Item key="m2">
                    <Link to={urls.mySupplier}>
                        Tedarikçi Ekle/Sil
                    </Link> 
                </Menu.Item>
                <Menu.Item key="m3">
                    <Link to={urls.supplierLimits}>
                        Tedarikçi Limitleri
                    </Link> 
                </Menu.Item>
            </SubMenu>
            <SubMenu
            key="sub4"
            title={
                <span>
                     <span>Raporlar</span>
                </span>
            }
            >
                <Menu.Item key="m5">
                    <Link to={urls.billStatus}>
                        Tüm Faturalar
                    </Link>                                            
                </Menu.Item>
                <Menu.Item key="m6">
                    <Link to={urls.invoiceAssigned}>
                        Temlik Edilenler
                    </Link>                                             
                    </Menu.Item>
                <Menu.Item key="m7">
                    <Link to={urls.invoiceDue}>
                        Vadesi Yaklaşanlar
                    </Link>                                             
                </Menu.Item>
                <Menu.Item key="m8">
                    <Link to={urls.supplierTransactions}>
                        Tedarikçi Hareketleri İzleme
                    </Link>                                             
                </Menu.Item>
                <Menu.Item key="m9">
                    <Link to={urls.allTransactions}>
                        Fatura Hareketleri İzleme
                    </Link>   
                </Menu.Item>
                <Menu.Item key="m10">
                    <Link to={urls.supplierUsageReport}>
                        Tedarikçi Kullanım Raporu
                    </Link>   
                </Menu.Item>

                <Menu.Item key="m11">
                    <Link to={urls.listTransactions}>
                        İşlemler Listesi
                    </Link>   
                </Menu.Item>
               
            </SubMenu>
                    </>
                ):
                (
                    <>
                              <SubMenu
                                  key="sub1"
                                  title={
                                      <span>
                                          <span>E-Müstahsil İşlemleri</span>
                                      </span>
                                  }
                              >
                                  <Menu.Item key="m0">
                                      <Link to={urls.updateMaturityMustahsil}>
                                      E-Müstahsil Vadesi Güncelleme
                                      </Link>
                                  </Menu.Item>

                                  <Menu.Item key="m1">
                                      <Link to={urls.uploadMustahsil}>
                                      E-Müstahsil Yükleme
                                      </Link>
                                  </Menu.Item>
                                  <Menu.Item key="m2">
                                      <Link to={urls.deleteAgriculturist}>
                                      E-Müstahsil Silme
                                      </Link>
                                  </Menu.Item>
                              </SubMenu>
                              <SubMenu
                                  key="sub2"
                                  title={
                                      <span>
                                          <span>Çiftçi İşlemleri</span>
                                      </span>
                                  }
                              >
                                  <Menu.Item key="m2">
                                      <Link to={urls.myAgriculturist}>
                                        Çiftçi Ekle/Sil
                                      </Link>
                                  </Menu.Item>
                                  <Menu.Item key="m3">
                                      <Link to={urls.agriculturistLimit}>
                                        Çiftçi Limitleri
                                      </Link>
                                  </Menu.Item>
                              </SubMenu>
                              <SubMenu
                                  key="sub4"
                                  title={
                                      <span>
                                          <span>Raporlar</span>
                                      </span>
                                  }
                              >
                                  <Menu.Item key="m5">
                                      <Link to={urls.allMustahsiller}>
                                      Tüm E-Müstahsiller
                                      </Link>
                                  </Menu.Item>
                                  <Menu.Item key="m6">
                                      <Link to={urls.invoiceAssignedMustahsil}>
                                          Temlik Edilenler
                                      </Link>
                                  </Menu.Item>
                                  <Menu.Item key="m7">
                                      <Link to={urls.invoiceDuesMustahsil}>
                                          Vadesi Yaklaşanlar
                                      </Link>
                                  </Menu.Item>
                                  <Menu.Item key="m8">
                                      <Link to={urls.supplierTransactionsMustahsil}>
                                      Çiftçi Hareketleri İzleme
                                      </Link>
                                  </Menu.Item>
                                  <Menu.Item key="m9">
                                      <Link to={urls.allTransactionsMustahsil}>
                                      E-Müstahsiller Hareketleri İzleme
                                      </Link>
                                  </Menu.Item>
                                  <Menu.Item key="m10">
                                    <Link to={urls.listTransactionsAgro}>
                                        İşlemler Listesi
                                    </Link>   
                                </Menu.Item>

                              </SubMenu>
                    </>
                )
            }
           
           
        </Menu>
    </>
  );
}

export default MobilMenu;
