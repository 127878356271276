import React, { useEffect, useState } from "react";
import { Row, Table, PaginationProps, Col, notification, Typography, DatePicker, Button } from "antd";
import moment from "moment";
import { CalendarOutlined } from "@ant-design/icons";
import { getAllImportInvoicesApi, getAllSuppliersApi } from "../services/invoiceApi";
import { chequeStatusMapByValue, convertFloatDotSeperated } from "../helpers/common";

const { Text } = Typography;

const BillStatus = () => {
  const [loading, setLoading] = useState(false);
  const [invoices, setInvoices] = useState<any>();
  const [filteredInfo, setFilteredInfo] = useState<any>(null);
  const [pageSize, setPageSize] = useState(10);
  const [offset, setOffset] = useState(1);
  const [totalInvoices, setTotalInvoices] = useState<any>();
  const [suppliersTitle, setSuppliersTitle] = useState<any>();
  const [filters, setFilters] = useState<any>({
    invoiceDateBegin: '',
    invoiceDateEnd: '',
    supplierTitle: '',
    invoiceStatus: '',
    invoiceTermBegin: '',
    invoiceTermEnd: '',
  });

  const statusList = [
    {
      text: 'Yüklendi',
      value: 'YUKLENDI',
    },
    {
      text: 'Temlik Edildi',
      value: 'TEMLIK_EDILDI',
    },
    {
      text: 'İskonto Talebinde Bulunuldu',
      value: 'ISKONTO_T_BULUNULDU',
    },
    {
      text: 'Reddedildi',
      value: "REDDEDILDI",
    },
    {
      text: 'Ön Ödeme Alındı',
      value: "ON_ODEME_YAPILDI",
    },
    {
      text: 'Vadesi Geçti',
      value: "VADESI_GECTI",
    },
    {
      text: 'Ödendi',
      value: "ODENDI",
    },
  ]

  const getAllInvoices  = async () => {  
    try {
      setLoading(true);
      const {
        invoiceDateBegin,
        invoiceDateEnd,
        supplierTitle,
        invoiceStatus,
        invoiceTermBegin,
        invoiceTermEnd
      } = filters;     

      const filterStr = `${invoiceDateBegin ? `createdDateBegin=${moment(invoiceDateBegin).format('YYYY-MM-DD')}&` : ''}
        ${invoiceDateEnd ? `createdDateEnd=${ moment(invoiceDateEnd).format('YYYY-MM-DD')}&` : ''}
        ${supplierTitle ? filters.supplierTitle : ''}
        ${invoiceStatus ? filters.invoiceStatus : ''}
        ${invoiceTermBegin ? `invoiceTermBegin=${ moment(invoiceTermBegin).format('YYYY-MM-DD')}&` : ''}
        ${invoiceTermEnd ? `invoiceTermEnd=${ moment(invoiceTermEnd).format('YYYY-MM-DD')}&`: ''}`;

       const response: any = await getAllImportInvoicesApi(filterStr.trim().replaceAll(/\s/g,''), pageSize, offset);
    
      if (response) {
        setLoading(false);
        setInvoices(response.data.invoiceRecords.data);
        setTotalInvoices(response.data.invoiceRecords);
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error)
      if(error.response.status === 500){
        notification.error({
          message: 'Beklenmedik bir hata oluştu.',
          duration: 3,
        });
      }
      
    }
  }

  const getAllSuppliers = async () => {
    try{
      const response:any = await getAllSuppliersApi()
      if(response){
        setSuppliersTitle(response?.data?.supplierTitles)
      }
    }catch(e:any){
      console.log(e)
    }
  }

  const checkDate = (checkdate: any, date1: any, date2:any) => {
    return moment(moment(checkdate).format('YYYY-MM-DD')).isBetween(moment(moment(date1).format('YYYY-MM-DD')), moment(moment(date2).format('YYYY-MM-DD')))
  }

  const handleReset = (clearFilters: any) => {
 
    clearFilters();
    setFilteredInfo(null);
    setFilters({
      ...filters,
      invoiceDateBegin: '',
      invoiceDateEnd: '',
      supplierTitle: '',
      invoiceStatus: '',
      invoiceTermBegin: '',
      invoiceTermEnd: '',
    })
  };

  const getColumnTimeProps = (dataIndex: string) => ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}: any) => (
      <div style={{ padding: 10, display: 'grid', justifyItems: 'center' }}>
        <Text style={{ fontWeight: 'bold', marginBottom: 10 }}>Tarih seçin veya aralık girin</Text>
        <DatePicker.RangePicker
          onChange={(e: any) => {
            setSelectedKeys(e.length ? [e] : [])
            setFilters({
              ...filters,
              invoiceDateBegin: (dataIndex === 'createdDate' && e.length > 0 && new Date(e[0]).toISOString()) || filters.invoiceDateBegin,
              invoiceDateEnd: (dataIndex === 'createdDate' && e.length > 0 && new Date(e[1]).toISOString()) || filters.invoiceDateEnd,
              invoiceTermBegin: (dataIndex === 'invoiceTerm' && e.length > 0 && new Date(e[0]).toISOString()) || filters.invoiceTermBegin,
              invoiceTermEnd: (dataIndex === 'invoiceTerm' && e.length > 0 && new Date(e[1]).toISOString()) || filters.invoiceTermEnd,
              supplierTitle: filters.supplierTitles
            })
          }}
          allowClear={false}
          style={{ width: 190 }}
          value={selectedKeys[0]}
          format="YYYY-MM-DD"
        />
        <div style={{ marginTop: 15 }}>
          <Button
            role="reset"
            style={{ width: 90 }}
            onClick={() => handleReset(clearFilters)}
            size="small"
          >
            Sıfırla
          </Button>
        </div>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <CalendarOutlined type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    filteredValue: (filteredInfo && filteredInfo[dataIndex]) || null,
    onFilter: (value: any, record: any) => record[dataIndex] ? checkDate(record[dataIndex],value[0], value[1]) : "",
    render: (text: string) => moment(text).format("DD-MM-YYYY")
  });

  const handleChange = (pagination: any, _filters: any) => {
    setOffset(pagination.current);
    setPageSize(pagination.pageSize);
    setFilters({
      ...filters,
      supplierTitle: _filters?.invoiceOwnerTitle !== null ? _filters?.invoiceOwnerTitle.map((item:any) => `supplierTitles=${encodeURIComponent(item)}&`).toString().split(',').join('')  : filters.suppliersTitle = "",
      invoiceStatus: _filters?.statusCode !== null ? _filters?.statusCode.map((item:any) => `invoiceStatuses=${item}&`).toString().split(',').join('')  : filters.invoiceStatus = "",
    })
    setFilteredInfo(_filters);
  };

  const columns: any = [
    {
      title: "Fatura No",
      dataIndex: "invoiceNumber",
      key: "invoiceNumber",
      fixed: 'left',
    },
    {
      title: "Tedarikçi Ünvanı",
      dataIndex: "invoiceOwnerTitle",
      key: "invoiceOwnerTitle",
      filters: suppliersTitle && suppliersTitle.map((item:any) =>{ return {text: item, value: item} }),
      filteredValue: (filteredInfo && filteredInfo.invoiceOwnerTitle) || null,
      onFilter: (value: string, record: any) => record.invoiceOwnerTitle.includes(value),
      ellipsis: true,

    },
    {
      title: "Fatura Yük. Tarihi",
      dataIndex: "createdDate",
      key: "createdDate",
      ...getColumnTimeProps('createdDate'),
    },
    {
      title: "Fatura Vadesi",
      dataIndex: "invoiceTerm",
      key: "invoiceTerm",
      ...getColumnTimeProps('invoiceTerm'),
    },
    {
      title: "Fatura Tutarı",
      dataIndex: "invoiceTotal",
      key: "invoiceTotal",
      render: convertFloatDotSeperated,
    },
    {
      title: "Fatura Durumu",
      dataIndex: "statusCode",
      key: "statusCode",
      filters: statusList || [],
      filteredValue: (filteredInfo && filteredInfo.statusCode) || null,
      onFilter: (value: string, record: any) => record.statusCode.includes(value),
      ellipsis: true,
      render: (value: any) => (
          <Text style={{ color: chequeStatusMapByValue[value]?.color, fontWeight: 'bold' }}>
              {chequeStatusMapByValue[value] && chequeStatusMapByValue[value].text}
          </Text>
      ),
    },
  ];

  useEffect(() => {
    getAllInvoices();
    getAllSuppliers();
    // eslint-disable-next-line react-hooks/exhaustive-deps   
  }, [offset, pageSize, filters])

  

  const showTotal: PaginationProps['showTotal'] = total => 
    <Row style={{width: '100%'}}>
        <Col lg={22} style={{textAlign: 'right', color: '#727272', fontSize: 15}}>Toplam {total}</Col>
    </Row>;

  const handleShowSize = (current: any, size: any) => {
    setOffset(current);
    setPageSize(size);
  }
 

  return (
    <main>
      <Row style={{ marginTop: 50 }}>
        <Col span={24}>
          <h3 className="page-title">Faturalarımın Durumu</h3>
        </Col>
      </Row>
      <Row >
        <Col span={24} style={{ margin: "0px auto" }}>
          <Table
            className="w-100 invoiceTable"
            pagination={{
              position: ["topRight", "none" as any],
              showLessItems: true,
              showTotal,
              showSizeChanger: true,
              pageSize,
              onShowSizeChange: handleShowSize,
              total: totalInvoices && totalInvoices.totalDataCount, 
            }}
            loading={loading}
            onChange={handleChange}
            dataSource={invoices}
            columns={columns}
            scroll={{
              x: 1200,
            }}
          />
        </Col>
      </Row>
    </main>
  );
};

export default BillStatus;
