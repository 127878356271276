import { ServiceHelper } from ".";

const uploadTedarikcisApi = async (formData: FormData): Promise<Object> => {
  return await ServiceHelper.post(`buyer/suppliers`, formData, {});
};

const uploadTedarikciInvoicesApi = async (formData: FormData): Promise<Object> => {
  return await ServiceHelper.post(`buyer/suppliers`, formData, {});
};

const uploadInvoicesApi = async (formData: FormData): Promise<Object> => {
  return await ServiceHelper.post(`invoices/xlsx`, formData, {});
  
};

const getTxtInvoicesDataApi = async (formData: FormData): Promise<Object> => {
  return await ServiceHelper.post(`invoices/txt/preview`, formData, {});
};

const uploadTxtInvoicesApi = async (formData: FormData): Promise<Object> => {
  return await ServiceHelper.post(`invoices/txt`, formData, {});
};


const getAllInvoicesFilesApi = async (): Promise<Object> => {
  return await ServiceHelper.get(`invoices/buyer/file-names`, {});
};

const getAllImportInvoicesApi = async (filters: string, pageSize: number, offset: number): Promise<Object> => {
  return await ServiceHelper.get(`invoices?${filters ? `${filters}` : ''}pageNumber=${offset}&pageSize=${pageSize}`, {});
};

const getAllInvoicesByFileNameApi = async (fileName: string): Promise<Object> => {
  return await ServiceHelper.get(`invoices/buyer/invoices?fileName=${fileName}`,
    {}
  );
};
const getAllAssignedInvoicesApi = async (filters: string, pageSize: number, offset: number): Promise<Object> => {
  return await ServiceHelper.get(`invoices/assigned?${filters ? `${filters}&` : ''}pageNumber=${offset}&pageSize=${pageSize}`, {});
};

const getAllAssignedInvoicesApii = async (filters: string, pageSize: number, offset: number): Promise<Object> => {
  return await ServiceHelper.get(`invoices/expiring?${filters ? `${filters}&` : ''}pageNumber=${offset}&pageSize=${pageSize}`, {});
};


const getAllAssignedSuppliers = async (): Promise<Object> => {
  return await ServiceHelper.get(`invoices/expiring/supplier-titles`, {});
};

const getAllAssignedSupplier = async (): Promise<Object> => {
  return await ServiceHelper.get(`invoices/assigned/supplier-titles`, {});
};

const getInvoicesUnusetApi = async (filters: string, pageSize: number, offset: number): Promise<Object> => {
  return await ServiceHelper.get(`invoices/unused?${filters ? `${filters}` : ''}pageNumber=${offset}&pageSize=${pageSize}`, {});
};

const deleteInvoiceUnused = async (invoiceIds: any): Promise<Object> => {
  return await ServiceHelper.deleteWithBody('invoices',invoiceIds, {});
};

const updateTermInvoiceApi = async (data: any): Promise<Object> => {
  return (
    await ServiceHelper.patch(`invoices/term`, data, {} )
  );
};

const getXmlInvoicesDataApi = async (formData: FormData): Promise<Object> => {
  return await ServiceHelper.post(`invoices/xml/preview`, formData, {});
};

const uploadXmlInvoicesApi = async (invoices: any): Promise<Object> => {
  return await ServiceHelper.post(`invoices/list`, {invoices}, {});
};

const getAllSuppliersApi = async (): Promise<Object> => {
  return await ServiceHelper.get(`invoices/supplier-titles`, {});
};

const getInvoicesSuppliersHistoryApi = async (supplierId:any, transactionType:any, startDate:string, finishDate: string, pageNumber:any, pageSize:any): Promise<Object> => {
  return  await ServiceHelper.get(`invoices/owners/${supplierId}/history?transactionDateBegin=${startDate}${finishDate ? `&transactionDateEnd=${finishDate}`: ''} ${transactionType ? `&transactionType=${transactionType}` : ''}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
    {}
  );
};

const getInvoicesXlsxDonwloadApiApi = async (supplierId:any, transactionType:any,  startDate:string, finishDate: string, pageNumber:any, pageSize:any): Promise<Object> => {
  return  await ServiceHelper.get(`invoices/suppliers/${supplierId}/history/xlsx?transactionDateBegin=${startDate}${finishDate ? `&transactionDateEnd=${finishDate}`: ''} ${transactionType ? `&transactionType=${transactionType}` : ''}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
    {}
  );
};

const getXmlInvoicesDataMustahsilApi = async (formData: FormData): Promise<Object> => {
  return await ServiceHelper.post(`invoices/agriculturist`, formData, {});
};

const getAllSuppliersUnusedApi = async (): Promise<Object> => {
  return await ServiceHelper.get(`invoices/unused/supplier-titles`, {});
};

const getSupplierTaxNumbersUnusedApi = async (): Promise<Object> => {
  return await ServiceHelper.get(`invoices/unused/supplier-tax-numbers`, {});
};



export {
  uploadTedarikcisApi,
  uploadInvoicesApi,
  uploadTxtInvoicesApi,
  getAllImportInvoicesApi,
  getAllInvoicesFilesApi,
  getAllAssignedInvoicesApi,
  getAllInvoicesByFileNameApi,
  getAllAssignedSuppliers,
  uploadTedarikciInvoicesApi,
  getTxtInvoicesDataApi,
  getAllAssignedInvoicesApii,
  getInvoicesUnusetApi,
  deleteInvoiceUnused,
  updateTermInvoiceApi,
  getXmlInvoicesDataApi,
  uploadXmlInvoicesApi,
  getAllSuppliersApi,
  getInvoicesSuppliersHistoryApi,
  getInvoicesXlsxDonwloadApiApi,
  getAllAssignedSupplier,
  getXmlInvoicesDataMustahsilApi,
  getAllSuppliersUnusedApi,
  getSupplierTaxNumbersUnusedApi


};