import React, { useState, useEffect } from "react";
import moment from "moment";
import { CalendarOutlined, SearchOutlined } from "@ant-design/icons";
import { Row, Table, PaginationProps, Typography, Col, DatePicker, Button, notification } from "antd";
import { getAllAssignedInvoicesApii, getAllAssignedSuppliers } from "../../services/invoiceApi";
import { convertFloatDotSeperated } from "../../helpers/common";

const { Text } = Typography;

function InvoiceDuesMustahsil() {
  const [loading, setLoading] = useState(false);
  const [ownersList, setOwnersList] = useState<any>();
  const [assignInvoices, setAssignInvoices] = useState<any>();
  const [pageSize, setPageSize] = useState(10);
  const [offset, setOffset] = useState(1);
  const [totalInvoices, setTotalInvoices] = useState<any>();
  const [filteredInfo, setFilteredInfo] = useState<any>(null);
  const [filters, setFilters] = useState<any>({
    invoiceDateBegin: '',
    invoiceDateEnd: '',
    supplierTitle: '',
    invoiceTermBegin: '',
    invoiceTermEnd: '',
  });
 

  const sortDate = (a: any, b: any)=> {
    var dateA = new Date(a.invoiceTerm).getTime();
    var dateB = new Date(b.invoiceTerm).getTime();
    return dateA > dateB ? 1 : -1; 
  }

  const getInvoiceAssigned = async () => {
    try {
      setLoading(true);
      const {
        invoiceDateBegin,
        invoiceDateEnd,
        supplierTitle,
        invoiceTermBegin,
        invoiceTermEnd
      } = filters;
      const filterStr = `${invoiceDateBegin ? `invoiceDateBegin=${invoiceDateBegin}&` : ''}
        ${invoiceDateEnd ? `invoiceDateEnd=${invoiceDateEnd}&` : ''}
        ${supplierTitle ? `supplierTitle=${supplierTitle}&` : ''}
        ${invoiceTermBegin ? `invoiceTermBegin=${invoiceTermBegin}&` : ''}
        ${invoiceTermEnd ? `invoiceTermEnd=${invoiceTermEnd}`: ''}`;
      
      const response: any = await getAllAssignedInvoicesApii(filterStr.trim().replaceAll(/\s/g,''), pageSize, offset);
  
      if (response && response.data) {
        setLoading(false);
        setTotalInvoices(response.data);
        setAssignInvoices(response.data.invoiceRecords.data.sort(sortDate));
      } else {
        setLoading(false);
      }
      const { data }: any = await getAllAssignedSuppliers();
      if (data) {
        setLoading(false);
        setOwnersList(data.supplierTitles.reduce((newData: any, owner: any) => {
          newData.push({text: owner, value: owner})
          return newData;
        }, []))
      } else {
        setLoading(false);
      }
    } catch (e:any) {
      console.log(e)
      if(e.response.status === 500){
        setLoading(false);
        notification.error({
          message: `Beklenmedik bir hata oluştu.`,
        });
      }      
    }
  };

  useEffect(() => {
    getInvoiceAssigned();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, pageSize]);


  const onClickFund = (fund: any) => {
    // navigate(urls.getfundDetail(fund.id));
  };

  const checkDate = (checkdate: any, date1: any, date2:any) => {
    return moment(moment(checkdate).format('YYYY-MM-DD')).isBetween(moment(moment(date1).format('YYYY-MM-DD')), moment(moment(date2).format('YYYY-MM-DD')))
  }

  const handleReset = (clearFilters: any) => {
    clearFilters();
    setFilteredInfo(null);
  };

  const handleSearch = (confirm: any) => {
    getInvoiceAssigned();
    confirm();
  }

  const getColumnTimeProps = (dataIndex: string) => ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}: any) => (
      <div style={{ padding: 10, display: 'grid', justifyItems: 'center' }}>
        <Text style={{ fontWeight: 'bold', marginBottom: 10 }}>Tarih seçin veya aralık girin</Text>
        <DatePicker.RangePicker
          onChange={(e: any) => {
            setSelectedKeys(e.length ? [e] : [])
            setFilters({
              ...filters,
              invoiceDateBegin: (dataIndex === 'invoiceDate' && e.length > 0 && new Date(e[0]).toISOString()) || filters.invoiceDateBegin,
              invoiceDateEnd: (dataIndex === 'invoiceDate' && e.length > 0 && new Date(e[1]).toISOString()) || filters.invoiceDateEnd,
              invoiceTermBegin: (dataIndex === 'invoiceTerm' && e.length > 0 && new Date(e[0]).toISOString()) || filters.invoiceTermBegin,
              invoiceTermEnd: (dataIndex === 'invoiceTerm' && e.length > 0 && new Date(e[1]).toISOString()) || filters.invoiceTermEnd,
              supplierTitle: filters.supplierTitles
            })
          }}
          allowClear={false}
          style={{ width: 190 }}
          value={selectedKeys[0]}
          format="YYYY-MM-DD"
        />
        <div style={{ marginTop: 15 }}>
          <Button
            role="reset"
            style={{ width: 90 }}
            onClick={() => handleReset(clearFilters)}
            size="small"
          >
            Sıfırla
          </Button>
          <Button
            type="primary"
            role="search"
            onClick={() => handleSearch(confirm)}
            style={{ width: 90, height: 24, borderRadius: 0, marginLeft: 8 }}
            icon={<SearchOutlined />}
            size="small"
          >
            Ara
          </Button>
        </div>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <CalendarOutlined type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    filteredValue: (filteredInfo && filteredInfo[dataIndex]) || null,
    onFilter: (value: any, record: any) => record[dataIndex] ? checkDate(record[dataIndex],value[0], value[1]) : "",
    render: (text: string) => moment(text).format("DD-MM-YYYY")
  });

  const handleChange = (pagination: any, _filters: any) => {
    setOffset(pagination.current);
    setPageSize(pagination.pageSize);
    setFilters({
      ...filters,
      supplierTitle: _filters?.invoiceOwnerTitle || filters.supplierTitle,
    })
    setFilteredInfo(_filters);
  };

  const tableCols: any = [
    {
      title: "Başvuru No",
      dataIndex: "applicationNumber",
      key: "applicationNumber",
      width: 130,
      fixed: 'left',
    },
    {
      title: "Çiftçi Ünvanı",
      dataIndex: "invoiceOwnerTitle",
      key: "invoiceOwnerTitle",
      width: 170,
      filters: ownersList || [],
      filteredValue: (filteredInfo && filteredInfo.invoiceOwnerTitle) || null,
      onFilter: (value: string, record: any) => record.invoiceOwnerTitle.includes(value),
      ellipsis: true,
    },
    {
      title: "Çiftçi VKN",
      dataIndex: "invoiceOwnerTaxNumber",
      key: "invoiceOwnerTaxNumber",
      width: 150,
    },
    {
      title: "E-Müstahsil No",
      dataIndex: "invoiceNumber",
      key: "invoiceNumber",
      width: 150,
    },
    {
      title: "E-Müstahsil Tarihi",
      dataIndex: "invoiceDate",
      key: "invoiceDate",
      ...getColumnTimeProps('invoiceDate'),
      width: 150,
    },
    {
      title: "E-Müstahsil Tutarı",
      dataIndex: "invoiceTotal",
      key: "invoiceTotal",
      render: convertFloatDotSeperated,
      width: 140,
    },
    {
      title: "E-Müstahsil Vadesi",
      dataIndex: "invoiceTerm",
      key: "invoiceTerm",
      ...getColumnTimeProps('invoiceTerm'),
      width: 160,
    },
    {
      title: "Temlik Edilen Kurum",
      dataIndex: "financialInstitutionName",
      key: "financialInstitutionName",
   
    },
  ];

  const showTotal: PaginationProps["showTotal"] = (total) => (
    <Row style={{ width: '100%' }}>
      <Col lg={12} style={{ textAlign: "right", color: "#727272", fontSize: 15 }} >
        Toplam {total}
      </Col>
    </Row>
  );

  const handleShowSize = (current: any, size: any) => {
    setOffset(current);
    setPageSize(size);
  }


  return (
    <main>
      <Row style={{ marginTop: 50 }}>
        <Col span={24}>
          <h3 className="page-title">Vadesi Yaklaşan E-Müstahsil</h3>
        </Col>
      </Row> 
      <Row>
        <Col span={24} style={{ margin: "0px auto" }}>
          <Table
            className="invoiceTable"
            rowKey="invoiceNumber"
            loading={loading}
            dataSource={assignInvoices}
            columns={tableCols}
            onChange={handleChange}
            pagination={{
              position: ["topRight", "none" as any],
              showLessItems: true,
              showTotal,
              showSizeChanger: true,
              pageSize,
              onShowSizeChange: handleShowSize,
              total: totalInvoices && totalInvoices.invoiceRecords.totalDataCount,
              
            }}
            scroll={{
              x: 1200,
            }}
            onRow={(fund) => ({
              onClick: () => onClickFund(fund),
            })}
          />
        </Col>
      </Row>
    </main>
  );
}

export default InvoiceDuesMustahsil;
