import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Table,
  Typography,
  notification,
  Popconfirm,
  Input,
  Button, 
  Form,
  Modal
} from "antd";
import {  PlusCircleOutlined , EditOutlined } from "@ant-design/icons";
import { getAllSuppliers, deleteSupplierById, postSupplier, updateUnregisteredSuppliersApi } from "../../services/supplierApi";
import {
  DeleteOutlined
} from '@ant-design/icons';
import {validateEmail, validateGsmNumber, validateVkn} from '../../helpers/validators';


const { Text } = Typography;


function SupplierList() {
  const [loading, setLoading] = useState(false);
  const [invoices, setInvoices] = useState<any>();
  const [psize, setPsize] = useState<any>(10);
  const [page, setpage] = useState<any>(1);
  const [totalDataCount, setTotalDataCount] = useState<any>(1);
  const [editModalShow, setEditModalShow] = useState(false);
  const [editUser, setEditUser] = useState<any>();
  const [supplierAdd] = Form.useForm();
  const [form] = Form.useForm();

  const fetchAllSuppliers  = async (page:any,psize:any) => {
    try {
      setLoading(true);
      const response: any = await getAllSuppliers(page,psize); 
      if (response) {
        setLoading(false);
        setInvoices(response.data.suppliers.data);
         setTotalDataCount(response.data.suppliers.totalDataCount)
     
      }
    } catch (error: any) {
      setLoading(false);   
    }
  }

  const handleShowSize = (current: any, size: any) => {
    setpage(current);
    setPsize(size);
  };

  const deletConfirm = async (value: any) => {
    try {
      setLoading(true);
      const response: any = await deleteSupplierById(value);
      if (response) {
        setLoading(false);
        fetchAllSuppliers(page,psize);
      }
    }
    catch(e){
      console.log(e)
    }
     finally {
      setLoading(false);
    }
  };

  const editSupplier = (value:any) => {
    if(value){
      setEditUser(invoices.find((item:any) => item.supplierId === value));
      setEditModalShow(true);
    }
  }

  const handleCancel = () => {
    setEditModalShow(false)
  }

  const onFinish = async (values:any) => {
    const newData = {
      email:values.email === undefined ? editUser?.email : values.email,
      nameSurname:values.nameSurname === undefined ? editUser?.name : values.nameSurname,
      supplierTaxId:values.supplierTaxId === undefined ? editUser?.supplierTaxNumber : values.supplierTaxId,
      supplierTitle:values.supplierTitle === undefined ? editUser?.supplierTitle : values.supplierTitle,
      gsmNumber:values.gsmNumber === undefined ? editUser?.gsmNumber : values.gsmNumber,
    }

    try{
      await updateUnregisteredSuppliersApi(editUser?.supplierId, newData);
      fetchAllSuppliers(page,psize);
      setEditModalShow(false);
      form.resetFields()
    }catch(e:any){
      console.log(e)
    }
  };

  const columns :any = [
    {
      title: 'İsim',
      dataIndex: 'name',
      key: 'name',
      width:220,
      fixed: 'left'
    },
    {
      title: 'E-posta',
      dataIndex: 'email',
      key: 'email',
      width:220,
    },
    {
      title: 'Telefon numarası',
      dataIndex: 'gsmNumber',
      key: 'gsmNumber',
      width:220,
    },
    {
      title: 'Tedarikçi Ünvanı',
      dataIndex: 'supplierTitle',
      key: 'supplierTitle',
      width:220,
    },
    {
      title: 'Vergi Kimlik No',
      dataIndex: 'supplierTaxNumber',
      key: 'supplierTaxNumber',    
      width:160,  
    },
    {
      title: '',
      dataIndex: 'supplierId',
      key: 'supplierId',
      align :'center',
      width: 40,
      render: (value: string, record: any) => (
        <>
       {(record && !record.isRegistered) ? (
          <Popconfirm
            title="Tedarikçiyi silmek istediğinizden emin misiniz?"
            onConfirm={() => deletConfirm(value)}
            overlayClassName="delete-pop"
            okText="Evet Sil"
            cancelText="Vazgeç"
          >
            <DeleteOutlined style={{fontSize: 18, color: 'red'}}/>
          </Popconfirm>
          ) : ''}
        </>
      ),
    },
    {
      title: '',
      dataIndex: 'supplierId',
      key: 'supplierId',
      align :'center',
      width: 40,
      render: (value: string, record: any) => (
        <>
       {(record && !record.isRegistered) ? (
          <EditOutlined 
          onClick={() => editSupplier(value)}
          style={{fontSize: 18, color: 'red'}}/>
          ) : ''}
        </>
      ),
    },
  ]
 
 
  const addSupplier = async (values:any) => {     
    try {     
      setLoading(true);
      const response: any = await postSupplier({
        supplierTaxId : values.supplierTaxNumber,
        supplierTitle : values.supplierTitle,
        email : values.email,
        gsmNumber : values.gsmNumber,
        nameSurname : values.name,      
      });     
        if (response) {
          setLoading(false);
          fetchAllSuppliers(page,psize);
          supplierAdd.resetFields();
        }
    } catch (e: any) {
      setLoading(false);
      console.log(e)
      if(e.response.data.Status === 409 && e.response.data.Type.includes('EmailAlreadyExistException')){
        notification.error({
            message: `E-mail bilgisi daha önce kullanılmışr.`,
        });
      }
      if(e.response.data.Status === 409 && e.response.data.Type.includes('SupplierWithSameTaxIdExistException')){
        notification.error({
            message: `Vergi Kimlik bilgisi daha önce kullanılmış.`,
        });
      }
    }
    
   };

   useEffect(() => {
    fetchAllSuppliers(page,psize);
  }, [page,psize]);

  const columnsInput: any = [
    {
      key: 'name',
      title: 'İsim',
      dataIndex: 'name',
      width:200,
      render: () =>  <Form.Item name="name"  
        rules={[{
          required: true, 
          message: "Ad Soyad girmelisiniz."
        }]}>
          <Input/>
        </Form.Item>
    },
    {
      title: 'E-posta',
      dataIndex: 'email',
      width:200,
      render: () => <Form.Item name="email"  
        rules={[{
          required: true, 
          validator: (_, value) => {
            const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            const patt = new RegExp(validRegex);
            return patt.test(value) 
                ? Promise.resolve()
                : Promise.reject(
                      new Error(
                          'Lütfen geçerli bir e-posta giriniz!'
                      )
                  );
        },
        }]}>
          <Input/>
        </Form.Item>
    },
    {
      title: 'Telefon numarası',
      dataIndex: 'gsmNumber',
      width:200,
      render: () => <Form.Item name="gsmNumber"  
        rules={[{
          required: true, 
          validator: (_, value) => {
            const patt = new RegExp('(5)[0-9][0-9][0-9]([0-9]){6}');
            return patt.test(value) && value.length < 11
                ? Promise.resolve()
                : Promise.reject(
                      new Error(
                          'Lütfen telefon numaranızı 5xxxxxxxxx şeklinde 10 basamak olarak giriniz!'
                      )
                  );
        },
        }]}>
          <Input/>
        </Form.Item>
    },
    {
      title: 'Tedarikçi Ünvanı',
      dataIndex: 'supplierTitle',
      width:200,
      render: () => <Form.Item name="supplierTitle"  
        rules={[{
          required: true, 
          message: "Tedarikçi Ünvanınızı girmelisiniz."
        }]}>
          <Input/>
        </Form.Item>
    },
    {
      title: 'Vergi Kimlik No',
      dataIndex: 'supplierTaxNumber',
      width:200,
      render: () => <Form.Item name="supplierTaxNumber"  
        rules={[{
          required: true, 
          validator: (_, value) => {
          return  value?.toString().length === 10 || value?.toString().length === 11
            ? Promise.resolve()
            : Promise.reject(new Error('Lütfen geçerli bir VKN / TCKN giriniz!'));
        },
        }]}>
          <Input/>
        </Form.Item>
    },
    {
      title: '',
      dataIndex: 'action',
      width: '50px',
      height :'30px',
      render: () => <Button htmlType="submit" 
      style={{border:'none', fontSize:'24px', alignItems:'center', display:'flex', height:'30px', background:'none'}}>
        <PlusCircleOutlined/></Button>
    }
  ];

  return (
    <Row>
    <Col span={24} style={{ marginBottom: 20 }}>
      <Text className="limit-title" >Tedarikçilerim</Text>
    </Col>
    <Col span={24}>
      <p>
      Platforma yüklediğiniz Tedarikçilerinizin listesini burada görebilirsiniz. Henüz kayıt olmamış olan Tedarikçilerinizi silebilirsiniz.
      </p>
    </Col>
      <Col span={24} style={{ margin: "0px auto" }}>

      <Form name="supplierAdd" form={supplierAdd} onFinish={addSupplier}>
          <Table 
            className="supplier-list"
            columns={columnsInput}
            dataSource={[{}]}
            pagination={false}
            scroll={{
              x: 900,
            }}
          />
      </Form>
        
        <Table
          className="w-100 invoiceTable"
          showHeader={false}
          pagination={{
            position: ["bottomLeft", "none" as any],
            showLessItems: true,
            showSizeChanger: true,
            current: page,
            pageSize:psize,
            total:totalDataCount, 
            onChange: handleShowSize,
          }}
          loading={loading}
          dataSource={invoices}
          columns={columns}
          rowKey="supplierId"
          scroll={{
            x: 900,
          }}
        />
      </Col>
      <Modal
        className="delete-invoices-modal"
        title="Tedarikçi Düzenle"
        open={editModalShow}
        onCancel={handleCancel}
        footer={false}
      >
         <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          style={{  margin: "auto", padding:'0 20px 0' }}
        >
          <Form.Item
            label="İsim"
            name="nameSurname"
          >
            <Input placeholder={editUser?.name} />
          </Form.Item>
          
          <Form.Item
            label="E-posta"
            name="email"
            rules={[
              {
                validator: (_, value) => {
                  if (!value) {
                    return Promise.resolve(); 
                  }
                  return validateEmail(value); 
                },
              },
            ]}
          >
            <Input placeholder={editUser?.email}  />
          </Form.Item>
          
          <Form.Item
            label="Telefon Numarası"
            name="gsmNumber"
            rules={[
              {
                validator: (_, value) => {
                  if (!value) {
                    return Promise.resolve(); 
                  }
                  return validateGsmNumber(value); 
                },
              },
            ]}
          >
            <Input placeholder={editUser?.gsmNumber} />
          </Form.Item>
          
          <Form.Item
            label="Ünvan"
            name="supplierTitle"
          >
            <Input placeholder={editUser?.supplierTitle}  />
          </Form.Item>
          
          <Form.Item
            label="Vergi Kimlik No"
            name="supplierTaxId"
            rules={[
              {
                validator: (_, value) => {
                  if (!value) {
                    return Promise.resolve(); 
                  }
                  return validateVkn(value); 
                },
              },
            ]}
          >
            <Input placeholder={editUser?.supplierTaxNumber}  />
          </Form.Item>
          
          <Form.Item style={{textAlign:'center'}}>
            <Button type="primary" htmlType="submit" block style={{maxWidth:'200px',  marginTop:'10px'}}>
              Güncelle
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </Row>
    
  );
}

export default SupplierList;
