import { ServiceHelper } from ".";

const getAgriculturistLimitsApi = async (): Promise<Object> => {
  return await ServiceHelper.get(`agriculturist/limits`,
    {}
  );
};

const updatedLimitApi = async (newData: any): Promise<Object> => {
  return await ServiceHelper.patch(`agriculturist/limit`, newData, {} );
};

const getAgriculturistPrePaidReportApi = async ( startDate:string, finishDate: string, financialData:any,supplierData:any, pageNumber:any, pageSize:any): Promise<Object> => {
  return  await ServiceHelper.get(`buyer/agriculturists/discounts/pre-paid-report?discountCreatedDateBegin=${startDate}&discountCreatedDateEnd=${finishDate}&${financialData}&${supplierData}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
    {}
  );
};

const getPrePaidReportXlsxApi = async (startDate:string, finishDate: string, financialData:any,supplierData:any, pageNumber:any, pageSize:any): Promise<Object> => {
  return  await ServiceHelper.get(`buyer/agriculturists/discounts/pre-paid-report/xlsx?discountCreatedDateBegin=${startDate}&discountCreatedDateEnd=${finishDate}&${financialData}&${supplierData}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
    {}
  );
};

const getImportTemplate = async (): Promise<Object> => {
  return (
    await ServiceHelper.get(`agriculturist/sample/template`, {})
  );
};

const uploadFarmerXlsApi = async (formData: FormData): Promise<Object> => {
  return await ServiceHelper.post(`agriculturist/agriculturists`, formData, {});
};

const getAgriculturistRegisteredApi = async (): Promise<Object> => {
  return (
    await ServiceHelper.get(`agriculturist/registered`, {})
  );
};

const getAgriculturistHistoryApi = async (supplierId:any, financialTitleId:any,  startDate:string, finishDate: string, pageNumber:any, pageSize:any): Promise<Object> => {
  return  await ServiceHelper.get(`agriculturist/risks/${supplierId}/history?openingDateBegin=${startDate}${finishDate ? `&openingDateEnd=${finishDate}`: ''}&financialInstitutionIds=${financialTitleId}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
    {}
  );
};

const getInvoicesOwnersHistoryApi = async (supplierId:any, transactionType:any, startDate:string, finishDate: string, pageNumber:any, pageSize:any): Promise<Object> => {
  return  await ServiceHelper.get(`invoices/owners/${supplierId}/history?transactionDateBegin=${startDate}${finishDate ? `&transactionDateEnd=${finishDate}`: ''} ${transactionType ? `&transactionType=${transactionType}` : ''}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
    {}
  );
};

const getXlsxDonwloadRiskApi = async (supplierId:any, financialTitleId:any,  startDate:string, finishDate: string, pageNumber:any, pageSize:any): Promise<Object> => {
  return  await ServiceHelper.get(`agriculturist/risks/${supplierId}/history/xlsx?openingDateBegin=${startDate}${finishDate ? `&openingDateEnd=${finishDate}`: ''}&financialInstitutionIds=${financialTitleId}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
    {}
  );
};

const getInvoicesXlsxAgroApi = async (supplierId:any, transactionType:any,  startDate:string, finishDate: string, pageNumber:any, pageSize:any): Promise<Object> => {
  return  await ServiceHelper.get(`invoices/agriculturists/${supplierId}/history/xlsx?transactionDateBegin=${startDate}${finishDate ? `&transactionDateEnd=${finishDate}`: ''} ${transactionType ? `&transactionType=${transactionType}` : ''}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
    {}
  );
};

export {
  getAgriculturistLimitsApi,
  updatedLimitApi,
  getAgriculturistPrePaidReportApi,
  getPrePaidReportXlsxApi,
  getImportTemplate,
  uploadFarmerXlsApi,
  getAgriculturistRegisteredApi,
  getAgriculturistHistoryApi,
  getInvoicesOwnersHistoryApi,
  getXlsxDonwloadRiskApi,
  getInvoicesXlsxAgroApi
};